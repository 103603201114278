<template>
  <v-form data-vv-scope="email">
    <v-layout class="mb-5">
      <v-flex xs8 class="pr-5">
        <v-layout row wrap class="mb-5">
          <v-flex xs12 class="sw-h5">{{
            $t("ticket_template_wizard.message_name_label")
          }}</v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="email.name"
              v-validate.disable="'required|min:3'"
              :error-messages="errors.collect('name')"
              :data-vv-name="'name'"
              :data-vv-as="$t('ticket_template_wizard.message_name_data_as')"
              :placeholder="
                $t('ticket_template_wizard.message_name_placeholder')
              "
              hide-details
            />
            <ErrorMessages :error-messages="errors.collect('email.name')" />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <TranslatableTooltip
              :content="$t('ticket_template_wizard.subject_tooltip')"
            >
              <div class="mb-4 sw-h5">
                {{ $t("ticket_template_wizard.subject") }}
              </div>
            </TranslatableTooltip>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="email.subject"
              v-validate.disable="'required'"
              data-vv-name="subject"
              :data-vv-as="$t('ticket_template_wizard.subject')"
              :placeholder="$t('ticket_template_wizard.subject_placeholder')"
              hide-details
            ></v-text-field>
            <ErrorMessages :error-messages="errors.collect('email.subject')" />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 class="pl-5">
        <v-card flat class="border-radius sw-primary-bg">
          <v-card-text class="py-5 text-center">
            <TranslatableTooltip
              :content="
                $t('ticket_template_wizard.message_header_image_tooltip')
              "
              class="center"
            >
              <div class="mb-2 sw-h5">
                {{ $t("ticket_template_wizard.message_header_image") }}
              </div>
            </TranslatableTooltip>
            <MediaManager
              v-model="email.message_image"
              :media-type="'images'"
              label=""
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="mb-5">
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.message_body_tooltip')"
        >
          <div class="mb-4 sw-h5">
            {{ $t("ticket_template_wizard.message_body") }}
          </div>
        </TranslatableTooltip>
        <QuillEditor
          v-model="email.body"
          :config="editorConfig"
          :show-login-link="true"
        />
        <ErrorMessages :error-messages="errors.collect('email.body')" />
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mb-5">
      <v-flex xs8 class="pr-5">
        <div class="mb-4 sw-h5">
          {{ $t("ticket_template_wizard.message_send_time") }}
        </div>
        <v-layout>
          <v-flex xs6 class="pr-5">
            <date-picker
              v-model="email.send_time_start"
              v-validate.disable="'required'"
              data-vv-name="send_time_start"
              :data-vv-as="$t('ticket_template_wizard.from')"
              width="99%"
              type="datetime"
              :first-day-of-week="1"
              format="MMMM DD, YYYY H:mm"
              :minute-step="5"
              :lang="datePickerLang"
              :placeholder="$t('ticket_template_wizard.from')"
              :editable="false"
              append-to-body
              @input="updateEndDate"
            ></date-picker>
            <ErrorMessages
              :error-messages="errors.collect('email.send_time_start')"
            />
          </v-flex>
          <v-flex xs6 class="pl-5">
            <date-picker
              v-model="email.send_time_end"
              width="100%"
              type="datetime"
              :first-day-of-week="1"
              format="MMMM DD, YYYY H:mm"
              :minute-step="5"
              :not-before="email.send_time_start"
              :lang="datePickerLang"
              :placeholder="$t('ticket_template_wizard.to')"
              :editable="false"
              append-to-body
            ></date-picker>
          </v-flex>
        </v-layout>
        <div class="px-2 grey--text sw-caption">
          {{ $t("current-timezone") }}: {{ userTimezone }}
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <TranslatableTooltip
          :content="$t('ticket_template_wizard.email_active_label_tooltip')"
        >
          <v-switch v-model="email.active" :true-value="1" :false-value="0">
            <template slot="label">
              <div class="black--text">
                {{ $t("ticket_template_wizard.email_active_label") }}
              </div>
            </template>
          </v-switch>
        </TranslatableTooltip>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";
import AudienceSelector from "@/components/AudienceSelector.vue";
import RecipientSelector from "@/components/RecipientSelector.vue";

export default {
  inject: ["parentValidator"],
  computed: {
    email: {
      get() {
        return this.$store.getters.ticketTemplateWizardEmail;
      },
      set(val) {
        this.$store.dispatch("setTicketTemplateWizardEmail", val);
      },
    },
    editorConfig() {
      return {
        shortcodes: true,
        label: "",
        placeholder: this.$t("ticket_template_wizard.message_body_placeholder"),
        style: {
          height: "400px",
          "--primary-color": "#1f363d",
          "--secondary-color": "#3869D4",
        },
      };
    },
  },
  components: {
    DatePicker,
    AudienceSelector,
    RecipientSelector,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    updateEndDate() {
      if (!this.email.send_time_end) return;

      const minEndDate = moment(this.email.send_time_start)
        .add(24, "hours")
        .toISOString();

      const isSameOrBefore = moment(this.email.send_time_end).isSameOrBefore(
        minEndDate,
      );

      if (isSameOrBefore || this.email.send_time_end === null) {
        this.email.send_time_end = minEndDate;
      }
    },
  },
};
</script>
